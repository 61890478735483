import React from 'react';
import classNames from 'classnames';

import useDeviceSize from 'src/utils/hooks/useDeviceSize';

import ImageC from '../Image';
import Input, { InputCProps } from '../Input';

import classes from './index.module.less';

interface Props extends InputCProps {}

const SearchBar = ({
	type = 'text',
	placeholder = 'Search items and games',
	allowClear = false,
	onClick = () => {},
	...props
}: Props) => {
	const deviceSize = useDeviceSize();
	const iconSize = deviceSize === 'mobile' ? 16 : 20;

	return (
		<div className={classes['search-bar']} onClick={onClick}>
			<div className={classes.icon}>
				<ImageC alt="icon search" src="/icons/icon-search.svg" width={iconSize} height={iconSize} />
			</div>
			<Input
				{...props}
				className={classNames(classes.input, props.className)}
				type={type}
				placeholder={placeholder}
				allowClear={
					allowClear
						? {
								clearIcon: (
									<ImageC src="/icons/icon-close.svg" width={iconSize} height={iconSize} />
								),
							}
						: false
				}
			/>
		</div>
	);
};

export default SearchBar;
