import { CSSProperties } from 'react';
import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	className?: string;
	width?: number | string;
	height?: number | string;
	color?: string;
	type?: 'horizontal' | 'vertical';
}

const Divider = ({
	className = '',
	width = 1,
	height = '100%',
	color = '#858584',
	type = 'horizontal',
}: Props) => {
	let styles: CSSProperties = {
		backgroundColor: color,
	};

	if (type === 'horizontal') {
		styles = {
			...styles,
			width: height,
			height: width,
		};
	} else if (type === 'vertical') {
		styles = {
			...styles,
			width: width,
			height: height,
		};
	}

	return (
		<div
			className={classNames(classes.divider, className)}
			style={{ ...styles }}
			// style={{ borderWidth: width, borderColor: color }}
		></div>
	);
};
export default Divider;
