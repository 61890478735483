import React from 'react';
import Input, { InputProps } from 'antd/lib/input/Input';
import Password from 'antd/lib/input/Password';
import classNames from 'classnames';

import ImageC from '../Image';

import classes from './index.module.less';
export interface InputCProps extends InputProps {
	inputRef?: any;
	showEyeIcon?: boolean;
	active?: boolean;
	highlight?: 'error';
}

const InputC = ({
	className = '',
	type = 'text',
	inputRef,
	showEyeIcon,
	active = true,
	highlight,
	...props
}: InputCProps) => {
	const classData = classNames(
		classes.input,
		className,
		{ [classes.inactive]: !active },
		highlight ? classes[highlight] : '',
	);

	return (
		<div className={classes['input-container']}>
			{type === 'password' ? (
				<Password
					className={classData}
					ref={inputRef}
					iconRender={(visible) =>
						showEyeIcon ? (
							<ImageC
								alt="icon eye"
								className={classes['icon-eye']}
								src={`/icons/icon-eye-${!visible ? 'open' : 'close'}.svg`}
								width={!visible ? 20 : 19}
								height={!visible ? 20 : 20}
							/>
						) : null
					}
					{...props}
				/>
			) : (
				<>
					<Input className={classData} type={type} ref={inputRef} {...props} />
				</>
			)}
		</div>
	);
};

export default InputC;
