import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import useDeviceSize from 'src/utils/hooks/useDeviceSize';

import Button from '../Button';
import Divider from '../Divider';
import Dropdown from '../Dropdown';
import HeaderSearch from './HeaderSearch';

import classes from './index.module.less';

interface Props {
	shadow?: boolean;
}

const AuthHeader = dynamic(() => import('src/components/Header/AuthHeader'), { ssr: false });

const Header = ({ shadow = false }: Props) => {
	const menus = [
		{
			name: 'Games',
			href: '/games',
		},
		{
			name: 'Marketplace',
			href: '/marketplace',
		},
		{
			name: 'Store',
			href: '/store',
		},
	];

	const earnMenus = [
		{ name: 'Swap Currency', href: '/earn/swap-currency' },
		{ name: 'Bridge', href: '/earn/bridge' },
		{ name: 'Staking', href: '/earn/staking' },
		{ name: 'CreoDeFi', href: 'https://defi.creoplay.app/' },
		// { name: 'Staking NFT', href: '/earn/staking-nft' },
		// { name: 'Launchpad', href: '/earn/launchpad' },
	];

	const deviceSize = useDeviceSize();

	const [scrolled, setScrolled] = useState<boolean>(false);

	const handleScroll = () => {
		if (window.scrollY >= 86) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	useEffect(() => {
		document.addEventListener('scroll', handleScroll);

		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<nav
			className={classNames(classes.navbar, {
				[classes.scrolled]: scrolled,
				[classes.shadow]: shadow,
			})}
		>
			<div className={classNames('container', classes.content)}>
				{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
				<Link className={classes.logo} href="/">
					{deviceSize !== 'mobile' ? (
						<ImageC alt="logo" src="/icons/creoplay-with-text.svg" height={33} width={131} />
					) : (
						<ImageC alt="logo" src="/icons/creoplay.svg" height={32} width={32} />
					)}
				</Link>

				{deviceSize === 'desktop' && (
					<div className={classes.menu}>
						{menus?.map(({ name, href }: any) => (
							<Link key={name} href={href}>
								{name}
							</Link>
						))}
					</div>
				)}

				<div className={classes['right-section']}>
					<HeaderSearch />

					<Dropdown
						position="left"
						control={
							<Button className={classes['earn-btn']}>
								Earn
								<ImageC alt="logo" src="/icons/icon-arrow-down.svg" height={4} width={6} />
							</Button>
						}
						menu={
							<div className={classes['earn-list']}>
								{earnMenus.map((menu) => (
									<Link key={menu.href} href={menu.href} className={classes['earn-item']}>
										{menu.name}
									</Link>
								))}
							</div>
						}
					/>

					<AuthHeader />
				</div>

				{deviceSize !== 'desktop' && (
					<Dropdown
						position="right"
						control={
							<ImageC
								className={classNames(classes.menu, 'cursor-pointer')}
								alt="menu"
								src="/icons/icon-menu.svg"
								height={deviceSize === 'tablet' ? 25 : 20}
								width={deviceSize === 'tablet' ? 32 : 25}
							/>
						}
						menu={
							<div className={classes.sidebar}>
								<ImageC
									alt="close"
									className="cursor-pointer"
									src="/icons/icon-close.svg"
									width={22}
									height={22}
								/>
								<Divider
									className={classes.divider}
									width={0.3}
									color="rgba(217, 217, 217, 0.30)"
								/>
								{menus?.map(({ name, href }: any) => (
									<Link key={name} href={href}>
										{name}
									</Link>
								))}
							</div>
						}
					/>
				)}
			</div>
		</nav>
	);
};

export default Header;
