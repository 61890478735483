import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import classes from './index.module.less';

interface Props {
	position?: 'left' | 'right';
	containerClassName?: string;
	controlClassName?: string;
	menuClassName?: string;
	control?: any;
	menu?: any;
}

const Dropdown = ({
	position = 'left',
	containerClassName = '',
	controlClassName = '',
	menuClassName = '',
	control,
	menu,
}: Props) => {
	const [show, setShow] = useState<boolean>(false);

	const controlClickedRef = useRef(false);

	const handleDropdownButtonClick = () => {
		controlClickedRef.current = true;

		if (show) {
			setShow(false);
		} else {
			setShow(true);
		}
	};

	const handleClickEventListener = () => {
		if (!controlClickedRef.current) {
			setShow(false);
		} else {
			controlClickedRef.current = false;
		}
	};

	useEffect(() => {
		document.body.addEventListener('click', handleClickEventListener);

		return () => {
			setShow(false);
			document.body.removeEventListener('click', handleClickEventListener);
		};
	}, []);

	return (
		<div
			className={classNames(classes.dropdown, classes[`position-${position}`], containerClassName)}
		>
			<div
				className={classNames(classes.dropbtn, controlClassName)}
				onClick={handleDropdownButtonClick}
			>
				{control}
			</div>
			<div
				className={classNames(classes['dropdown-content'], { [classes.show]: show }, menuClassName)}
			>
				{menu}
			</div>
		</div>
	);
};

export default Dropdown;
