import Grid from 'antd/lib/grid';

export default function useDeviceSize() {
	const { xs, sm, md, lg } = Grid.useBreakpoint();

	let DS: 'desktop' | 'tablet' | 'mobile' = 'desktop';

	if ((xs || sm) && !md) {
		DS = 'mobile';
	} else if (!lg && md) {
		DS = 'tablet';
	} else {
		DS = 'desktop';
	}

	return DS;
}
